import { initializeApp } from '@firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore/lite';

const firebaseConfig = {
  apiKey: 'AIzaSyCxTdmBJlidpTfMnPo8svWKFbQtWtAqonw',
  authDomain: 'asthma-log-dbc59.firebaseapp.com',
  projectId: 'asthma-log-dbc59',
  storageBucket: 'asthma-log-dbc59.appspot.com',
  messagingSenderId: '914204306044',
  appId: '1:914204306044:web:73a70742e50d2c0a2237f9',
  measurementId: 'G-04P3RTPM7N',
};

const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

if (process.env.NODE_ENV === 'development') {
  const firebasePort = process.env.firebasePort ? parseInt(process.env.firebasePort) : 8080;
  connectFirestoreEmulator(db, 'localhost', firebasePort);
  console.log(`Using firestore emulator at localhost: ${firebasePort}!`);

  const authPort = process.env.authPort || 9099;
  connectAuthEmulator(auth, `http://localhost:${authPort}`);
  console.log(`Using firestore auth at localhost: ${authPort}!`);
}

export default firebaseApp;
export { db, auth };
