import MenuIcon from '@mui/icons-material/Menu';
import { Button, IconButton } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useMatches from '../../hooks/useMatches';
import NavigationMenu from '../NavigationMenu/NavigationMenu';
import { IMenuItem } from '../NavigationMenu/NavigationMenuItems';

const Navigation: FC = () => {
  const { matchesMd } = useMatches();

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const pageItems: IMenuItem[] = useMemo(
    () => [
      {
        type: 'item',
        key: 'dashboard',
        label: 'Übersicht',
        onClick: () => navigate('dashboard'),
      },
    ],
    [],
  );

  const renderMobile = useMemo(
    () => (
      <>
        <IconButton
          size='large'
          color='inherit'
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <MenuIcon />
        </IconButton>
        <NavigationMenu
          id='nav-menu-appbar'
          horizontalAlign={'left'}
          anchorEl={anchorEl}
          handleClose={() => setAnchorEl(null)}
          itemList={pageItems}
        />
      </>
    ),
    [pageItems, anchorEl],
  );

  const renderDesktop = useMemo(
    () => (
      <>
        {pageItems
          .filter((item) => item.type === 'item')
          .map((item) => (
            <Button key={item.key} onClick={item.onClick} sx={{ color: 'white', display: 'block' }}>
              {item.label}
            </Button>
          ))}
      </>
    ),
    [pageItems],
  );

  return matchesMd ? renderMobile : renderDesktop;
};

export default Navigation;
