import { Divider, MenuItem, Typography } from '@mui/material';
import { FC } from 'react';

export interface IMenuItem {
  type: 'item' | 'divider';
  key: string;
  label?: string | null;
  onClick?: () => void;
}

type MenuItemsProps = {
  itemList: IMenuItem[];
};

const MenuItems: FC<MenuItemsProps> = ({ itemList }) => {
  return (
    <>
      {itemList.map((item) => {
        return item.type === 'item' ? (
          <MenuItem key={item.key} onClick={item.onClick}>
            <Typography textAlign='center'>{item.label}</Typography>
          </MenuItem>
        ) : (
          <Divider key={item.key} />
        );
      })}
    </>
  );
};

export default MenuItems;
