import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import useAuthContext from './../firebase/auth/auth-context';

const Login = lazy(() => import('../pages/Login/Login'));
const AccountSetup = lazy(() => import('../pages/Setup/AccountSetup'));
const Dashboard = lazy(() => import('../pages/Dashboard/Dashboard'));

const AppRoutes = () => {
  const { user } = useAuthContext();

  return (
    <Routes>
      {user ? (
        <>
          <Route path='/account-setup' index element={<AccountSetup />} />
          <Route path='*' index element={<Dashboard />} />
        </>
      ) : (
        <>
          <Route path='login' index element={<Login />} />
          <Route path='*' element={<Navigate to='/login' />} />
        </>
      )}
    </Routes>
  );
};

export default AppRoutes;
