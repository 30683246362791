import { useMediaQuery, useTheme } from '@mui/material';

const useMatches = () => {
  const theme = useTheme();

  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
  const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
  const matchesXl = useMediaQuery(theme.breakpoints.down('xl'));

  return { matchesSm, matchesMd, matchesLg, matchesXl };
};

export default useMatches;
