import { Typography } from '@mui/material';
import { FC } from 'react';

const PatientSelector: FC = () => {
  return (
    <Typography variant='h6' component='div'>
      Kalle
    </Typography>
  );
};

export default PatientSelector;
