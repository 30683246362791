import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { LocaleContextProvider } from '../context/locale.context';
import { AuthContextProvider } from '../firebase/auth/auth-context';
import App from './App';
import AppThemeProvider from '../theme/AppThemeProvider';

const queryClient = new QueryClient();

const AppWrapper = () => {
  return (
    <BrowserRouter>
      <AppThemeProvider>
        <LocaleContextProvider>
          <AuthContextProvider>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </AuthContextProvider>
        </LocaleContextProvider>
      </AppThemeProvider>
    </BrowserRouter>
  );
};

export default AppWrapper;
