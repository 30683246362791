import { AccountCircle } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useLocaleContext from '../../context/locale.context';
import useAuthContext from '../../firebase/auth/auth-context';
import { IMenuItem } from '../NavigationMenu/NavigationMenuItems';
import NavigationMenu from './../NavigationMenu/NavigationMenu';

const UserMenu: FC = () => {
  const { t } = useLocaleContext();
  const { user, logout } = useAuthContext();

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const settingItems: IMenuItem[] = useMemo(
    () => [
      { type: 'item', key: 'user', label: user?.displayName ?? t.userMenu.userNameFallback },
      {
        type: 'item',
        key: 'account-setup',
        label: t.userMenu.accountSetup,
        onClick: () => navigate('account-setup'),
      },
      { type: 'divider', key: 'dev1' },
      { type: 'item', key: 'logout', label: t.userMenu.logout, onClick: logout },
    ],
    [],
  );

  return (
    <>
      <IconButton
        size='large'
        color='inherit'
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <AccountCircle />
      </IconButton>
      <NavigationMenu
        id='user-menu-appbar'
        horizontalAlign={'right'}
        anchorEl={anchorEl}
        handleClose={() => setAnchorEl(null)}
        itemList={settingItems}
      />
    </>
  );
};

export default UserMenu;
