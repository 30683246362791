import * as lite from 'firebase/firestore/lite';

import { auth, db } from '../config/firebase.config';

export class BasicDataService<T> {
  collectionRef: lite.CollectionReference;
  userId: string | undefined;
  whereUserId: lite.QueryFieldFilterConstraint;

  constructor(collectionTitle: string) {
    this.collectionRef = lite.collection(db, collectionTitle);
    this.userId = auth?.currentUser?.uid;
    this.whereUserId = lite.where('userId', '==', this.userId);
  }

  addUidToValue = (value: T) => {
    const user = auth.currentUser;
    return { ...value, userId: user?.uid };
  };

  getAll = async () => {
    const getAllQuery = lite.query(this.collectionRef, this.whereUserId);
    const docList = await lite.getDocs(getAllQuery);
    return docList;
  };

  getById = async (id: string) => {
    const docRef = lite.doc(this.collectionRef, id);
    const docSnap = await lite.getDoc(docRef);
    if (docSnap.exists() && docSnap.data().userId === this.userId) {
      return docSnap;
    } else {
      return null;
    }
  };

  createOrUpdate = async (values: T, id?: string) => {
    let docRef;
    if (id) {
      // Update document
      docRef = lite.doc(this.collectionRef, id);
    } else {
      // Create new document
      docRef = lite.doc(this.collectionRef);
    }
    await lite.setDoc(docRef, this.addUidToValue(values));
    return docRef.id;
  };

  delete = async (id: string) => {
    const docRef = lite.doc(this.collectionRef, id);
    await lite.deleteDoc(docRef);
  };
}
