import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    background: {
      default: '#b9bfdf',
    },
    primary: {
      main: '#9d1b1e',
    },
    secondary: {
      main: '#7e8bc2',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
        },
      },
    },
  },
});
