import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';
import useLocaleContext from '../../context/locale.context';

type ErrorFallbackProps = {
  error: Error;
  resetErrorBoundary: () => void;
};

const ErrorFallback: FC<ErrorFallbackProps> = ({ error, resetErrorBoundary }) => {
  const { t } = useLocaleContext();

  return (
    <Box
      width={'100%'}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Typography variant={'h4'}>{t.errorFallback.info}</Typography>
      <Typography>{error.message}</Typography>
      <Button onClick={resetErrorBoundary}>{t.errorFallback.retry}</Button>
    </Box>
  );
};

export default ErrorFallback;
