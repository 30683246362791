import {
  browserLocalPersistence,
  browserSessionPersistence,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
  User,
} from 'firebase/auth';
import { createContext, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useLocaleContext from '../../context/locale.context';

import { auth } from '../config/firebase.config';
import { AppUser } from '../models/AppUser';
import userService from '../services/user.service';

type AuthContextProviderProps = {
  children: React.ReactNode;
};

export interface ILoginFormData {
  email: string;
  password: string;
  rememberMe?: boolean;
}

interface IAuthContext {
  user: User | null;
  login: (loginFormData: ILoginFormData) => void;
  logout: () => void;
}

const AuthContext = createContext({
  user: null,
  login: () => true,
  logout: () => true,
} as IAuthContext);

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const { t } = useLocaleContext();
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);

  const getUserId = async (currentUser: User) => {
    const userId = currentUser.uid;
    const user = await userService.getById(userId);
    if (!user) {
      const newUser = { name: currentUser.displayName, email: currentUser.email } as AppUser;
      await userService.createOrUpdate(newUser, userId);
    }
  };

  onAuthStateChanged(auth, async (currentUser) => {
    if (currentUser && Object.keys(currentUser).length > 0 && currentUser.uid !== user?.uid) {
      setUser({ ...currentUser });
      await getUserId(currentUser);
    } else if ((!currentUser || Object.keys(currentUser).length === 0) && !!user?.email) {
      setUser(null);
    }
  });

  const login = useCallback(async ({ email, password, rememberMe = false }: ILoginFormData) => {
    try {
      const persistence = rememberMe ? browserLocalPersistence : browserSessionPersistence;
      await setPersistence(auth, persistence);
      await signInWithEmailAndPassword(auth, email, password);
      toast.success(t.login.loginSucceed);
    } catch (error) {
      toast.error(t.login.loginFailed);
    }
  }, []);

  const logout = useCallback(async () => {
    await signOut(auth);
    navigate('/login');
  }, [navigate]);

  return <AuthContext.Provider value={{ user, login, logout }}>{children}</AuthContext.Provider>;
};

const useAuthContext = () => useContext(AuthContext);

export default useAuthContext;
