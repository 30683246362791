import { Backdrop, CircularProgress } from '@mui/material';

type LoadingSpinnerProps = {
  isOpen: boolean;
  handleClose: () => void;
};

const LoadingSpinner = ({ isOpen, handleClose }: LoadingSpinnerProps): JSX.Element => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isOpen}
      onClick={handleClose}
    >
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};

export default LoadingSpinner;
