import { createContext, useContext } from 'react';

import deDE from './../i18n/de.json';

type LocaleContextProps = {
  children: React.ReactNode;
};

interface ILocaleContext {
  t: typeof deDE;
}

const LocaleContext = createContext({
  t: deDE,
} as ILocaleContext);

export const LocaleContextProvider = ({ children }: LocaleContextProps) => {
  return <LocaleContext.Provider value={{ t: deDE }}>{children}</LocaleContext.Provider>;
};

const useLocaleContext = () => useContext(LocaleContext);

export default useLocaleContext;
