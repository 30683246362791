import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import { theme } from './AppTheme.style';

interface AppStyleProviderProps {
  children: JSX.Element;
}

const AppThemeProvider = ({ children }: AppStyleProviderProps) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: { backgroundColor: theme.palette.background.default },
        }}
      />
      {children}
    </ThemeProvider>
  );
};

export default AppThemeProvider;
