import { AppBar, Box, Container, Toolbar } from '@mui/material';
import { FC } from 'react';
import PatientSelector from '../PatientSelector/PatientSelector';
import Navigation from './Navigation';
import UserMenu from './UserMenu';

const AppTopBar: FC = () => {
  return (
    <AppBar position={'static'}>
      <Container maxWidth={'xl'}>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 0, mr: 1 }}>
            <Navigation />
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: { xs: 'center', md: 'start' },
              mx: 2,
            }}
          >
            <PatientSelector />
          </Box>

          <Box sx={{ flexGrow: 0, ml: 1 }}>
            <UserMenu />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default AppTopBar;
