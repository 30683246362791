import { Menu } from '@mui/material';
import { FC } from 'react';
import MenuItems, { IMenuItem } from './NavigationMenuItems';

type NavigationMenuProps = {
  id: string;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  itemList: IMenuItem[];
  horizontalAlign: number | 'center' | 'left' | 'right';
};

const NavigationMenu: FC<NavigationMenuProps> = ({
  id,
  anchorEl,
  handleClose,
  itemList,
  horizontalAlign,
}) => {
  return (
    <Menu
      sx={{ mt: { xs: '35px', sm: '45px' } }}
      id={id}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: horizontalAlign,
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: horizontalAlign,
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItems itemList={itemList} />
    </Menu>
  );
};

export default NavigationMenu;
