import { getDocs, query, where } from 'firebase/firestore/lite';
import { AppUser } from '../models/AppUser';

import { BasicDataService } from './basic.service';

class UserDataService extends BasicDataService<AppUser> {
  constructor() {
    super('users');
  }

  getByEmail = (email: string) => {
    const docRef = query(this.collectionRef, where('email', '==', email));
    return getDocs(docRef);
  };
}

export default new UserDataService();
