import { Box } from '@mui/material';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopBar from '../components/AppTopBar/AppTopBar';
import LoadingSpinner from '../components/LoadingSpinner/loading-spinner';
import useAuthContext from '../firebase/auth/auth-context';
import ErrorFallback from '../pages/ErrorFallback/ErrorFallback';
import AppRoutes from './AppRoutes';

const App = () => {
  const { user } = useAuthContext();

  return (
    <Box className={'asthma-log-app'}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ToastContainer
          position='top-right'
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
          theme='colored'
        />
        {user && <TopBar />}
        <Suspense
          fallback={
            <LoadingSpinner
              isOpen
              handleClose={() => {
                return true;
              }}
            />
          }
        >
          <AppRoutes />
        </Suspense>
      </ErrorBoundary>
    </Box>
  );
};

export default App;
